import Connect from 'app/redux/connect'
import Redux from 'app/interface/redux'
import Form from 'components/base/form'
import React, { useEffect, useState } from 'react'
import Overlay from 'react-loading-overlay'
import Input from 'components/base/form/interface/input'
import Basic from 'app/redux/actions/pages/business/basic'
import { FacebookPixel, FBConnectedInstagramAccount } from '@marketing-milk/interfaces'
import { businessManagerService } from 'app/service/business-manager'
import { get } from 'lodash'

interface Option {
  readonly label: string
  readonly value: string
}

const Facebook = (props: Redux) => {
  const { main } = props.pages.business
  const [loading, setLoading] = useState(false)
  const [instagram, setInstagram] = useState<FBConnectedInstagramAccount[]>([])
  const [pixelOptions, setPixelOptions] = useState<Option[]>([])
  const [offlineOptions, setOfflineOptions] = useState<Option[]>([])

  const addOfflineOption = (id: string) => {
    setOfflineOptions([...offlineOptions, { value: id, label: `Offline Event Set - ${id}` }])
  }

  useEffect(() => {
    initFetch()
  }, [main.fb_account_id])

  const shouldFetch = () => {
    fetch()
  }

  const initFetch = () => {
    const { fb_account_id } = props.pages.business.main
    if (!!fb_account_id) {
      fetch()
    }
  }

  const fetch = async () => {
    setLoading(true)
    const results = await fetchFBConnectedAccounts()
    setInstagram(results.instagrams)
    const options = results.pixels.map((p: FacebookPixel) => ({
      value: p.id,
      label: `${p.isDataset ? 'Dataset' : 'Pixel'} - ${p.name} (${p.id})`,
    }))
    setPixelOptions(options)
    setOfflineOptions(
      !options.find(p => p.value === main.offline_event_set_id) && !!main.offline_event_set_id
        ? [
            ...options.filter(o => !o.label.startsWith('Pixel')),
            {
              value: main.offline_event_set_id,
              label: `Offline Event Set - ${main.offline_event_set_id}`,
            },
          ]
        : options.filter(o => !o.label.startsWith('Pixel'))
    )
    setLoading(false)
  }

  const currentPixel = () => pixelOptions.find(p => p.value === main.fb_dataset_id)
  const currentOffline = () => offlineOptions.find(p => p.value === main.offline_event_set_id)

  const fetchFBConnectedAccounts = async () => {
    return await businessManagerService.getFBConnectedAccounts(
      `${main.fb_account_id}`,
      props.pages.business.id,
      props.pages.business.main.fb_ad_authorizer_id ?? undefined
    )
  }

  return (
    <Overlay active={loading} spinner text="Fetching information about this business' ad account">
      <Form
        save={false}
        onChange={(col, label) => {
          Basic.update(col, label)
          if (col === 'use_offline_id') {
            Basic.update('offline_event_set_id', '')
          }
        }}
        onSubmit={() => null}
        groups={[
          {
            header: 'Facebook',
            rows: [
              {
                column: 6,
                fields: [
                  {
                    column: 'fb_account_id',
                    text: 'Account ID',
                    type: 'regular',
                    value: main.fb_account_id || '',
                    onBlur: shouldFetch,
                  } as Input,
                  {
                    column: 'fb_page_id',
                    text: 'Page ID',
                    type: 'regular',
                    value: main.fb_page_id || null,
                  } as Input,
                ],
              },
              {
                column: 6,
                fields: [
                  {
                    column: 'instagram_id',
                    text: 'Instagram Page',
                    type: 'select',
                    options: instagram,
                    value:
                      instagram.find((x: any) => x.id === main.instagram_id) ?? {
                        id: main.instagram_id,
                      } ??
                      null,
                    selector: {
                      getOptionLabel: x => x.username ?? x.id,
                      getOptionValue: x => x.id,
                      value: 'id',
                      clearable: true,
                    },
                    onChange: (column: string, value: any) => Basic.update(column, value.id),
                  } as Input,
                ],
              },
            ],
          },
          {
            header: 'Facebook Datasets',
            rows: [
              {
                column: 6,
                fields: [
                  {
                    column: 'fb_dataset_id',
                    text: 'Dataset ID',
                    type: 'select',
                    options: pixelOptions,
                    value: pixelOptions.find((x: Option) => x.value === main.fb_dataset_id) ?? {
                      value: main.fb_dataset_id,
                    },
                  } as Input,
                  {
                    type: 'toggle',
                    column: 'use_offline_id',
                    text: 'Use Separate Dataset for Offline Data?',
                    value: main.use_offline_id,
                  } as Input,
                ],
              },
              {
                column: 6,
                fields: [
                  {
                    column: 'offline_event_set_id',
                    text: 'Offline ID',
                    type: 'select_create',
                    disabled: !main.use_offline_id,
                    options: offlineOptions,
                    value: currentOffline(),
                    onCreate: (value: string) => {
                      addOfflineOption(value)
                      Basic.update('offline_event_set_id', value)
                    },
                  } as Input,
                ],
              },
            ],
          },
        ]}
      />
      <div className="col-sm-12">
        {((!main.offline_event_set_id && currentPixel()?.label.startsWith('Pixel')) ||
          (!!main.offline_event_set_id && currentOffline()?.label.startsWith('Pixel'))) && (
          <div className="alert alert-outline-danger alert-dismissible fade show" role="alert">
            <strong>Please Note</strong>&nbsp; Uploads will not work. The ID you are uploading
            offline data to is a Pixel. It must be a Dataset or Offline Event Set in order to
            properly upload Data. Please either provide a proper Dataset or Offline Event Set ID
          </div>
        )}
        {!!main.offline_event_set_id && currentOffline()?.label.startsWith('Offline') && (
          <div className="alert alert-outline-danger alert-dismissible fade show" role="alert">
            <strong>Please Note</strong>&nbsp; Starting May 2025, uploads will not work for this
            business, as this Offline ID belongs to an Offline Event Set and uses the Offline
            Conversions API, which is being deprecated. Please convert this Offline Event Set to a
            Dataset ID and use that instead.
          </div>
        )}
        <p className="text-default">
          With the current configuration, this business will upload <strong>offline data</strong>{' '}
          via the{' '}
          <strong>
            {currentOffline()?.label.startsWith('Offline') ? 'Offline ' : ''} Conversions API
          </strong>
          &nbsp; to the <strong>{currentOffline()?.label ?? currentPixel()?.label}</strong> and pull
          <strong> online data</strong> from the <strong>{currentPixel()?.label}</strong>.
        </p>
      </div>
      <div className="col-sm-12 pb-5">
        <p className="text-info">
          By default, the system will upload offline data to the Dataset ID selected above, as well
          as pull online data from that Dataset. If you want to use a separate Dataset for offline
          data, check the box above and select the Offline ID from the dropdown.
        </p>
        <p className="text-warning">
          This supports Offline Event Sets for now, however this will not be supported starting May
          2025. This option should only be used if necessary. A good example use case would be if
          your Dataset is still a Pixel on FB's system, you can convert your Offline Event Set to a
          Dataset and use it in the Dataset ID field. Or, if you are part of a franchise that shares
          a master pixel for online data, but would like to use a separate Dataset ID for offline
          data.
        </p>
      </div>
    </Overlay>
  )
}

export default Connect(Facebook)
