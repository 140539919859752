import Bleed from './bleed'
import LabelInterface from './label'
import { ValueType } from 'react-select'
import { InputComponents } from '../components/input'
import { getOptionLabel, getOptionValue } from 'react-select/src/builtins'

export default interface Input extends Bleed, LabelInterface {
  column: string
  className?: string
  disabled?: boolean
  onBlur?: () => void
  isValid?: Function
  type: keyof InputComponents
  placeholder?: string
  selector?: {
    clearable?: boolean
    getOptionLabel?: getOptionLabel
    getOptionValue?: getOptionValue
    isMulti?: boolean
    value?: string
  }
  schema?: {
    [key: string]: any
  }
  subType?: InputRegularType
  options?: any[]
  readOnly?: boolean
  value: string | boolean | ValueType<any, boolean>
  onChange: (column, label) => void
  onCreate?: (value) => void
  other?: {
    regular?: boolean
    prepend: any
    datalist: {
      options: string[]
      id: string
    }
  }
}

export enum InputRegularType {
  Color = 'color',
  Date = 'date',
  Text = 'text',
  Password = 'password',
  Phone = 'tel',
  Number = 'number',
  Email = 'email',
  Website = 'url',
}

interface SelectorOptions {
  label: any
  value: string
}
