import { Auth0Role, CreateUserDTO, Pod } from '@marketing-milk/interfaces'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import axios from 'axios'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import * as yup from 'yup'
import { BusinessRaw } from '../../../../app/interface/redux/types/data/generic/businesses'
import { userService } from '../../../../app/service/user'
import { CustomSelect } from '../shared/CustomSelect'
import { RoleSelection } from '../shared/RoleSelection'
import Box from '@material-ui/core/Box'
import { NestAPI } from '../../../../app/nestapi'

type UserCreateType = {
  auth0Roles: Auth0Role[] | undefined
  businesses: BusinessRaw[]
  pods: Pod[]
  isLoading: boolean
  canManageRoles: boolean
  onSave: () => void
}

type SelectionSchema<ItemType, ValueType> = {
  label: string
  value: ValueType
  item: ItemType
}

interface FormSchemaValidation
  extends Pick<CreateUserDTO, 'firstName' | 'lastName' | 'email' | 'notificationEmail'> {
  auth0Roles: SelectionSchema<Auth0Role, string>[]
  businesses: SelectionSchema<BusinessRaw, number>[] | undefined
  podID: SelectionSchema<Pod, number> | undefined
}

const validationSchema = yup.object({
  firstName: yup.string().max(50).required('First Name is Required'),
  lastName: yup.string().max(50).required('Last Name is Required'),
  email: yup.string().email('Enter a valid email').required('Email is required'),
  notificationEmail: yup
    .string()
    .email('Enter a valid email')
    .required('Notification Email is required'),
  auth0Roles: yup
    .array(yup.object({}))
    .min(1, 'At least 1 role is required')
    .required('At least 1 role is required'),
})

const UserCreate = ({ auth0Roles, businesses, pods, onSave, canManageRoles }: UserCreateType) => {
  const [open, setOpen] = useState(false)
  const initialValues: FormSchemaValidation = {
    firstName: '',
    lastName: '',
    email: '',
    notificationEmail: '',
    businesses: [],
    auth0Roles: [],
    podID: undefined,
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async values => {
      const {
        email,
        notificationEmail,
        firstName,
        lastName,
        auth0Roles: roles,
        businesses,
      } = values

      const userDTO: CreateUserDTO = {
        email,
        notificationEmail,
        firstName,
        lastName,
        podID: values.podID?.value,
        auth0Roles: roles?.map(role => role.item),
        businessIDs: businesses?.map(b => b.value),
      }

      setOpen(false)

      try {
        //TODO: we have two userService files: one in admin and one in frontend. Put this in frontend.
        const { data: newUser, errors } = await userService.create(userDTO)

        errors.forEach(err => {
          toast.error(err.description)
        })

        if (newUser) {
          onSave()
          toast.success(newUser.message)
        }
      } catch (e) {
        if (axios.isAxiosError(e) && e.response?.data) {
          e.response.data.errors.forEach(err => {
            toast.error(JSON.stringify(err.description))
          })
        } else {
          toast.error(
            `We could not update ${userDTO.firstName} ${userDTO.lastName}'s account at this time`
          )
        }
      }
    },
  })

  const handleClose = () => {
    formik.resetForm()
    setOpen(false)
  }

  return (
    <>
      <Button
        startIcon={<AddIcon />}
        data-testid="create-user-btn"
        variant="contained"
        color="primary"
        onClick={() => setOpen(true)}
      >
        <span>Create</span>
      </Button>

      <Dialog
        open={open}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Create User</DialogTitle>
        <form className="form" noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
          <DialogContent>
            <div className="container-fluid">
              <div className="row">
                <div className="col-6 p-1">
                  <TextField
                    fullWidth
                    inputProps={{ 'data-testid': 'user-first-name' }}
                    variant="outlined"
                    id="firstName"
                    name="firstName"
                    label="First Name"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                    helperText={formik.touched.firstName && formik.errors.firstName}
                  />
                </div>
                <div className="col-6 p-1">
                  <TextField
                    fullWidth
                    variant="outlined"
                    inputProps={{ 'data-testid': 'user-last-name' }}
                    id="lastName"
                    name="lastName"
                    label="Last Name"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                    helperText={formik.touched.lastName && formik.errors.lastName}
                  />
                </div>
                <div className="col-6 p-1">
                  <TextField
                    fullWidth
                    variant="outlined"
                    inputProps={{ 'data-testid': 'user-email' }}
                    id="email"
                    name="email"
                    label="Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </div>

                <div className="col-6 p-1">
                  <TextField
                    fullWidth
                    variant="outlined"
                    inputProps={{ 'data-testid': 'user-notification-email' }}
                    id="notificationEmail"
                    name="notificationEmail"
                    label="Notification Email"
                    value={formik.values.notificationEmail}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.notificationEmail && Boolean(formik.errors.notificationEmail)
                    }
                    helperText={formik.touched.notificationEmail && formik.errors.notificationEmail}
                  />
                </div>

                <div className="col-6 p-1">
                  <RoleSelection
                    formik={formik}
                    auth0Roles={auth0Roles}
                    canManageRoles={canManageRoles}
                    open={open}
                  />
                  {formik.touched?.auth0Roles && formik.errors?.auth0Roles && (
                    <Typography style={{ paddingLeft: 15 }} color="error" variant="caption">
                      {formik.errors.auth0Roles}
                    </Typography>
                  )}
                </div>

                <div className="col-6 p-1">
                  <CustomSelect
                    options={businesses.map(b => ({ label: b.businessName, value: b.id }))}
                    onChange={value => formik.setFieldValue('businesses', value)}
                    clearSelection={!open}
                    placeHolderText="Business"
                    fieldName="businesses"
                    isMulti={true}
                  />
                </div>

                <div className="col-6 p-1">
                  <CustomSelect
                    options={pods.map(p => ({ label: p.podName, value: p.id }))}
                    onChange={value => {
                      formik.setFieldValue('podID', value)
                    }}
                    clearSelection={!open}
                    placeHolderText="Pod"
                    fieldName="podID"
                    isMulti={false}
                    disabled={
                      formik.values.auth0Roles.filter(role => role.item.name === 'Client').length >
                      0
                    }
                  />
                </div>
              </div>
            </div>
            <Box
              sx={{
                bgcolor: '#fafafa',
                padding: '1rem',
                marginTop: '1rem',
                boxShadow: 'rgba(160, 68, 255, 0.5) 4px 0px 0px 0px inset',
              }}
            >
              <div style={{ marginBottom: '1rem' }}>
                {`The email address you enter must be the `}
                <em style={{ fontWeight: '600' }}>same</em>
                {` email address you use to sign up in your welcome email.`}
              </div>
              <div>
                <span style={{ fontWeight: '600' }}>{`If using Facebook: `}</span>
                {`Email address must be the `}
                <em style={{ fontWeight: '600' }}>same</em>
                {' email you used to create your Facebook account.'}
              </div>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary" data-testid="create-user-save">
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}

export default UserCreate
