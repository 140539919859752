import React from 'react'
import CreatableSelect from 'react-select/creatable'
import Interface from '../../interface/input'

interface Option {
  readonly label: string
  readonly value: string
}

export const selectCreateFn = ({
  column,
  disabled = false,
  onChange,
  onCreate,
  options = [],
  value,
}: Interface) => {
  const doChange = (e: any) => {
    onChange(column, e.value)
  }

  return (
    <div>
      <CreatableSelect
        isClearable={false}
        isDisabled={disabled}
        onChange={doChange}
        onCreateOption={(value: string) => {
          onCreate && onCreate(value)
          return value
        }}
        options={options}
        value={value ?? ''}
        getOptionLabel={(e: Option) => {
          return e.label
        }}
        getOptionValue={(e: Option) => {
          return e.value
        }}
      />
    </div>
  )
}

export default selectCreateFn
